import React from 'react';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import Button from '../components/Button';
import Action from '../components/Resource/Action';
import Video from '../components/Video';
import Icon from '../components/Icon';
import Image from '../components/Image';
import Subfooter from '../components/Subfooter/index';
import Hero from '../components/Hero';
import PartnershipPromo from '../components/PartnershipPromo';
import './styles.scss';

const CareerProfile = ({ data, pageContext: { orderLookup, relatedMap } }) => {
  const profile = data.allCareerProfilesYaml.edges[0].node;
  const sortIndex = parseInt(orderLookup[profile.parent.name]);
  const maxCareerIndex = parseInt(orderLookup['max']);

  return (
    <Layout
      title={`${profile.title} | Career Profiles`}
      className="career-profile"
    >
      <Hero
        mediaSource="img-lead-careers.png"
        textOverlay={{ heading: profile.title }}
      />
      <Section className="pt-1">
        <Container fullWidth>
          <Row>
            <Column>
              <p className="mb-0.5">
                <Link to="/students">&lsaquo; View All Career Profiles</Link>
              </p>
              <Container fullWidth className="profile__video-container">
                <div className="profile__video-wrapper">
                  {sortIndex > 1 && (
                    <Link to={`/career-profiles/${orderLookup[sortIndex - 1]}`}>
                      <Icon
                        marginRight
                        name="arrowleft"
                        className="profile__switch-button"
                      />
                    </Link>
                  )}
                  <Video
                    className="profile__video"
                    guidSrc={profile.videoSrc}
                    poster={profile.videoPoster}
                  />
                  {sortIndex < maxCareerIndex && (
                    <Link to={`/career-profiles/${orderLookup[sortIndex + 1]}`}>
                      <Icon
                        marginLeft
                        name="arrowright"
                        className="profile__switch-button"
                      />
                    </Link>
                  )}
                </div>
              </Container>
            </Column>
          </Row>
          <Row className="pt-1">
            <Column size={8}>
              <h3 className="mt-1">{profile.introHeader}</h3>
              <p
                dangerouslySetInnerHTML={{ __html: profile.introParagraph }}
                className="profile__intro-paragraph"
              />
              <div className="profile__pdf-button-wrapper mt-2">
                {profile.studentActivationPDF && (
                  <Action
                    label="Student Activation"
                    download={profile.studentActivationPDF}
                    target="_blank"
                    rel="noreferrer"
                    className="profile__pdf-button"
                  >
                    Student Activation
                  </Action>
                )}
                {profile.careerProfilePDF && (
                  <Action
                    label="Career Profile"
                    download={profile.careerProfilePDF}
                    target="_blank"
                    rel="noreferrer"
                    className="profile__pdf-button"
                  >
                    Career Profile
                  </Action>
                )}
              </div>
              <PartnershipPromo className="mt-4" />
            </Column>
            <Column size={4}>
              <div className="profile__quote-wrapper">
                <Image
                  filename={profile.quoteImage}
                  className="profile__quote-img mt-1"
                />
                <div className="profile__quote mt-3">
                  <p
                    dangerouslySetInnerHTML={{ __html: profile.quote }}
                    className="profile__quote-body"
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: profile.quoteAttribution,
                    }}
                    className="mt-1.5"
                  />
                  <p className="profile__quote-sub-quote">
                    STEM Careers Coalition COVID-19 Solution Seeker
                  </p>
                </div>
              </div>
            </Column>
          </Row>
          <Row className="mt-3">
            <Column>
              <h3 id="additional">Additional Careers to explore</h3>
            </Column>
          </Row>
          <Row>
            <Column size={6}>
              {profile.related[0] && (
                <>
                  <Image
                    filename={relatedMap[profile.related[0]].relatedImage}
                  />
                  <h4 className="mt-1">
                    {relatedMap[profile.related[0]].title}
                  </h4>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: relatedMap[profile.related[0]].shortDescription,
                    }}
                    className="profile__related-description"
                  />
                  <Button to={`/career-profiles/${profile.related[0]}`}>
                    Learn More
                  </Button>
                </>
              )}
            </Column>
            <Column size={6}>
              {profile.related[1] && (
                <>
                  <Image
                    filename={relatedMap[profile.related[1]].relatedImage}
                  />
                  <h4 className="mt-1">
                    {relatedMap[profile.related[1]].title}
                  </h4>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: relatedMap[profile.related[1]].shortDescription,
                    }}
                    className="profile__related-description"
                  />
                  <Button to={`/career-profiles/${profile.related[1]}`}>
                    Learn More
                  </Button>
                </>
              )}
            </Column>
          </Row>
        </Container>
      </Section>
      <Section>
        <Subfooter />
      </Section>
    </Layout>
  );
};

export default CareerProfile;

export const query = graphql`
  query CareerTemplateQuery($id: String!) {
    allCareerProfilesYaml(filter: { id: { eq: $id } }) {
      edges {
        node {
          title
          sortIndex
          introHeader
          introParagraph
          related
          relatedImage
          studentActivationPDF
          careerProfilePDF
          videoPoster
          videoSrc
          quoteImage
          quote
          quoteAttribution
          parent {
            ... on File {
              name
            }
          }
        }
      }
    }
  }
`;
