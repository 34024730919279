import React from 'react';
import { Container } from '../Grid';
import Image from '../Image';
import './styles.scss';

const PartnershipPromo = ({ className }) => (
  <Container className={className}>
    <div className="partnership-promo">
      <p className="pt-2 text-bold">
        ASME IS A PROUD PARTNER OF THE STEM CAREERS COALITION
      </p>
      <div className="partnership-promo__logo-container pb-2">
        <Image filename="logo-asme-blue.svg" />
        <Image filename="logo-stem-career-coalition.svg" />
      </div>
    </div>
  </Container>
);

export default PartnershipPromo;
